import { ToastContent, TypeOptions, toast } from 'react-toastify';

export const showToastify = (content: string | ToastContent, type: TypeOptions) => {
  return toast(content, {
    position: 'top-right',
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    bodyClassName: 'pr-1',
    type
  });
};
