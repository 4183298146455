import React from 'react';
import { NavLinkItem } from '@components/layout/Sidebar/components/NavLinkItem';
import { MessagesNavItem, PayoutRequestNavItem, RequestNavItem } from '@components/layout/Sidebar/components/customNavItems';
import { useGettext } from '@cranium/i18n';
import { IdentityTypeEnum } from '@/interfaces/brokrete';
import { usePermissions, useClientSettings } from '@/shared/access';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { Link } from '@/shared/navigation';

export interface MenuItem {
  name: string;
  allowed: boolean;
  withProtectedRenderer?: IdentityTypeEnum[];
  component: JSX.Element;
}

type SidebarStructure = Array<
  { type: 'single'; item: MenuItem } | { type: 'grouped'; title: string; openByDefault: boolean; items: MenuItem[] }
>;

export const useSidebarStructure = () => {
  const permissions = usePermissions();
  const settings = useClientSettings();
  const { gettext } = useGettext();

  const menuItems: Record<string, MenuItem> = {
    orders: {
      name: 'orders',
      allowed: get(permissions, 'orders.show'),
      component: <NavLinkItem iconClass="icon-orders" label={gettext('Orders')} path="/orders" />
    },
    finance: {
      name: 'finance',
      allowed: get(permissions, 'finances.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <PayoutRequestNavItem />
    },
    tax: {
      name: 'tax',
      allowed: get(permissions, 'taxes.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-dollar-card" label={gettext('Tax')} path="/tax" />
    },
    projects: {
      name: 'projects',
      allowed: get(permissions, 'projects.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-project-1" label={gettext('Projects')} path="/projects" />
    },
    pallets: {
      name: 'pallets',
      allowed: get(permissions, 'pallets.show') && get(settings, 'pallets.visible'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-pallets" label={gettext('Pallets')} path="/pallets" />
    },
    products: {
      name: 'products',
      allowed: get(permissions, 'products.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-pie-chart" label={gettext('Products')} path="/products" />
    },
    locations: {
      name: 'locations',
      allowed: get(permissions, 'locations.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-factory" label={gettext('Locations')} path="/locations/list" />
    },
    contractors: {
      name: 'contractors',
      allowed: get(permissions, 'contractors.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-contractors" label={gettext('Contractors')} path="/contractors" />
    },
    messages: {
      name: 'messages',
      allowed: get(permissions, 'messages.show'),
      component: <MessagesNavItem />
    },
    availability: {
      name: 'availability',
      allowed: get(permissions, 'block_off_availability.show'),
      component: (
        <NavLinkItem
          iconClass="icon-block-off-availability"
          label={gettext('Block-Off Availability')}
          path="/availability/block-off"
        />
      )
    },
    requests: {
      name: 'requests',
      allowed: get(settings, 'requests.visible') && get(permissions, 'requests.show'),
      component: <RequestNavItem />
    },
    suppliers: {
      name: 'suppliers',
      allowed: get(settings, 'suppliers.visible') && get(permissions, 'suppliers.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-suppliers" label={gettext('Suppliers')} path="/suppliers" />
    },
    inventory: {
      name: 'inventory',
      allowed: settings.inventory.visible && get(permissions, 'inventory.show') && !!settings.inventory.redirect_to,
      component: (
        <NavLinkItem
          iconClass="icon-store-mall-directory"
          path=""
          href={settings.inventory.redirect_to || ''}
          label={gettext('Inventory')}
        />
      )
    },
    analytics: {
      name: 'analytics',
      allowed: settings.analytics.visible && get(permissions, 'analytics.show') && !isEmpty(settings.analytics.redirect_to),
      component: (
        <NavLinkItem
          iconClass="icon-line-chart"
          path=""
          href={settings.analytics.redirect_to || ''}
          label={gettext('Analytics')}
        />
      )
    },
    autoCollect: {
      name: 'portal',
      allowed: get(permissions, 'auto_collect.show') && settings.auto_collect.visible && !!settings.auto_collect.redirect_to,
      component: (
        <NavLinkItem iconClass="icon-global" path="" href={settings.auto_collect.redirect_to || ''} label={gettext('Portal')} />
      )
    },
    coupons: {
      name: 'coupon',
      allowed: true,
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-tag" label={gettext('Coupon Generation')} path="/coupon" />
    }
  };

  const structure: SidebarStructure = [
    { type: 'single', item: menuItems.orders },
    { type: 'single', item: menuItems.finance },
    { type: 'single', item: menuItems.projects },
    { type: 'single', item: menuItems.pallets },
    { type: 'single', item: menuItems.contractors },
    { type: 'single', item: menuItems.suppliers },
    { type: 'single', item: menuItems.messages },
    {
      type: 'grouped',
      title: gettext('Settings'),
      openByDefault: true,
      items: [
        menuItems.coupons,
        menuItems.locations,
        menuItems.products,
        menuItems.availability,
        menuItems.tax,
        menuItems.requests
      ]
    },
    { type: 'single', item: menuItems.inventory },
    { type: 'single', item: menuItems.analytics },
    { type: 'single', item: menuItems.autoCollect }
  ];

  return structure;
};
